import { OUTPUT_FORMATS, computeMuxRate } from "../../Config/output";
import {
  outputResolutionOptionsProvider,
  integrationsOptionsProvider,
  inputSourceOptionsProvider,
  audioSourcesOptionsProvider,
  feedTypeOptionsProvider,
  programContentOptionsProvider,
} from "../../Config/providers";

const formSetup = {
  forms: {
    EnableVideoStream: {
      title: "VideoStream Disabled",
      titletranslation: "videostream.disabled",
      endpoint: "v1/#0/organizations/#1/fixtures/#2/videostream/enable",
      parameters: ["sport", "organizationId", "fixtureId"],
      method: "POST",
      successmessage: "VideoStream Enabled",
      successmessagetranslation: "videostream.enabled",
      submit: "enable",
      submittranslation: "enable",
      headingIcon: "fas fa-exclamation-circle",
      submitIcon: "fas fa-check",
      submitClass: "btn-success",
      formClass: "danger",
      fields: []
    },
    DisableVideoStream: {
      title: "VideoStream Enabled",
      titletranslation: "videostream.enabled",
      endpoint: "v1/#0/organizations/#1/fixtures/#2/videostream/disable",
      parameters: ["sport", "organizationId", "fixtureId"],
      method: "POST",
      successmessage: "VideoStream Disabled",
      successmessagetranslation: "videostream.disabled",
      submit: "disable",
      submittranslation: "disable",
      headingIcon: "fas fa-check-circle",
      submitIcon: "fas fa-times",
      submitClass: "btn-danger",
      formClass: "success",
      fields: []
    },
    StartVideoStream: {
      title: "Start VideoStream",
      titletranslation: "videostream.start",
      endpoint: "v1/#0/organizations/#1/fixtures/#2/videostream/start",
      parameters: ["sport", "organizationId", "fixtureId"],
      method: "POST",
      successmessage: "VideoStream Starting",
      successmessagetranslation: "videostream.starting",
      submit: "Start",
      submittranslation: "start",
      // headingIcon: "fas fa-check-circle",
      submitIcon: "fas fa-play",
      // submitClass: "btn-danger",
      formClass: "videostream-start",
      fields: []
    },
    AddSubscription: {
      title: "Add Subscription/s",
      titletranslation: "subscriptions.add",
      endpoint: "/v1/#0/organizations/#1/fixtures/#2/output",
      parameters: ["sport", "organizationId", "fixtureId"],
      method: "CUSTOM",
      successmessage: "Subscription/s Added",
      successmessagetranslation: "subscriptions.added",
      submit: "Add Subscriptions",
      submittranslation: "subscriptions.add",
      formClass: "subscription-options",
      fields: [
        {
          name: "feedType",
          size: "col-md-6 col",
          label: "Feed Type:",
          labeltranslation: "feedType",
          type: "select",
          placeholder: "",
          required: true,
          initial: "##PRIMARY",
          options: feedTypeOptionsProvider
        },
        {
          name: "content",
          size: "col-md-6 col",
          label: "Content:",
          labeltranslation: "content",
          type: "select",
          placeholder: "",
          required: true,
          initial: "##PROGRAM",
          options: programContentOptionsProvider
        },
        {
          name: "audio",
          size: "col-md-4 col",
          label: "Audio:",
          labeltranslation: "audio",
          type: "select",
          placeholder: "Audio",
          required: true,
          initial: "##BOTH",
          options: audioSourcesOptionsProvider
        },
        {
          name: "outputFormat",
          size: "col-md-2 col",
          label: "Output Format:",
          labeltranslation: "outputformat",
          type: "select",
          placeholder: "",
          required: true,
          initial: "##RTMP",
          options: OUTPUT_FORMATS
        },
        {
          name: "outputURL",
          size: "col-md-6 col",
          label: "Output URL:",
          labeltranslation: "outputurl",
          type: "text",
          placeholder: "",
          required: (field, values) => ["RTMP", "SRT"].includes(values.outputFormat),
          condition: "outputFormat",
          conditionValue: (field, values) => ["RTMP", "SRT"].includes(field.value),
          initial: "",
          validate: "streamURL",
          errors: {
            validate: "Expected valid value"
          }
        },
        {
          name: "outputResolution",
          size: "col-md-4 col",
          label: "Output Resolution:",
          labeltranslation: "outputresolution",
          type: "select",
          placeholder: "",
          required: (field, values) => ["RTMP", "SRT"].includes(values.outputFormat),
          initial: "##2000",
          options: outputResolutionOptionsProvider,
          condition: "outputFormat",
          conditionValue: (field, values) => ["RTMP", "SRT"].includes(field.value)
        },
        {
          name: "muxRate",
          size: "col-md-2 col",
          label: "Output mux rate:",
          labeltranslation: "mux.rate",
          type: "number",
          help: "in bps",
          required: (field, values) => values.outputFormat === "SRT",
          initial: "muxRate",
          min: 0,
          computeWatch: ["outputFormat", "outputResolution"],
          compute: computeMuxRate,
          condition: "outputFormat",
          conditionValue: "SRT"
        },
        {
          name: "streamName",
          size: "col-md-3 col",
          label: "Stream Name / Key:",
          labeltranslation: "stream.name",
          type: "text",
          placeholder: "",
          required: (field, values) => values.outputFormat === "RTMP",
          initial: "streamName",
          validate: "streamName",
          errors: {
            validate: "Allowed characters are letters, numbers and the following symbols: . : / & _ ? = ; - @ \\"
          },
          condition: "outputFormat",
          conditionValue: "RTMP"
        },
        {
          name: "subscriptionName",
          size: "col-md-3 col",
          label: "Subscription Name",
          labeltranslation: "subscription.name",
          type: "text",
          placeholder: "",
          required: true,
          initial: "subscriptionName"
        }
      ],
      customActions: [
        {
          action: "subscriptions.add.close",
          label: "Close",
          color: "outline-secondary",
          align: "right",
          rightIcon: "fas fa-times"
        }
      ]
    },
    AddSubscriptionForIntegration: {
      title: "Add Subscription/s",
      titletranslation: "subscriptions.add",
      endpoint: "v1/#0/organizations/#1/fixtures/#2/output",
      parameters: ["sport", "organizationId", "fixtureId"],
      method: "POST",
      successmessage: "Subscription/s Added",
      successmessagetranslation: "subscriptions.added",
      submit: "Add Subscriptions",
      submittranslation: "subscriptions.add",
      formClass: "subscription-options",
      fields: [
        {
          name: "audio",
          size: "col-md-12 col",
          label: "Audio:",
          labeltranslation: "audio",
          type: "select",
          placeholder: "Audio",
          required: true,
          initial: "##BOTH",
          options: audioSourcesOptionsProvider
        },
        {
          name: "outputFormat",
          size: "col-md-3 col",
          label: "Output Format:",
          labeltranslation: "outputformat",
          type: "select",
          placeholder: "",
          required: true,
          initial: "##RTMP",
          options: OUTPUT_FORMATS
        },
        {
          name: "outputURL",
          size: "col-md-9 col",
          label: "Output URL:",
          labeltranslation: "outputurl",
          type: "text",
          placeholder: "",
          required: (field, values) => ["RTMP", "SRT"].includes(values.outputFormat),
          condition: "outputFormat",
          conditionValue: (field, values) => ["RTMP", "SRT"].includes(field.value),
          initial: "",
          validate: "streamURL",
          errors: {
            validate: "Expected valid value"
          }
        },
        {
          name: "muxRate",
          size: "col-md-3 col",
          label: "Output mux rate:",
          labeltranslation: "mux.rate",
          type: "number",
          help: "in bps",
          required: (field, values) => values.outputFormat === "SRT",
          initial: "muxRate",
          min: 0,
          computeWatch: ["outputFormat", "outputResolution"],
          compute: computeMuxRate,
          condition: "outputFormat",
          conditionValue: "SRT"
        },
        {
          name: "streamName",
          size: "col-md-3 col",
          label: "Stream Name / Key:",
          labeltranslation: "stream.name",
          type: "text",
          placeholder: "",
          required: (field, values) => values.outputFormat === "RTMP",
          initial: "streamName",
          validate: "streamName",
          errors: {
            validate: "Allowed characters are letters, numbers and the following symbols: . : / & _ ? = ; - @ \\"
          },
          condition: "outputFormat",
          conditionValue: "RTMP"
        },
        {
          name: "subscriptionName",
          size: "col-md-3 col",
          label: "Subscription Name",
          labeltranslation: "subscription.name",
          type: "text",
          placeholder: "",
          required: true,
          initial: "subscriptionName"
        }
      ],
      customActions: [
        {
          action: "subscriptions.add.close",
          label: "Close",
          color: "outline-secondary",
          align: "right",
          rightIcon: "fas fa-times"
        }
      ],
      encoder: (values, formData) => {
        const payload = {
          ...formData,
          ...values
        };
        return payload;
      }
    },
    SubscriptionRow: {
      title: "New Subscription",
      titletranslation: "subscription.new",
      endpoint: "v1/#0/organizations/#1/fixtures/#2/output",
      parameters: ["sport", "organizationId", "fixtureId"],
      method: "POST",
      successmessage: "Subscription Added Successfully",
      successmessagetranslation: "subscription.add.success",
      submit: "",
      submitIcon: "fas fa-plus",
      submitClass: "btn-success",
      fields: [
        {
          name: "audio",
          size: "col-md-5 col",
          label: "Audio:",
          type: "text",
          placeholder: "",
          required: true,
          hidden: true,
          initial: "audio"
        },
        {
          name: "content",
          size: "col-md-5 col",
          label: "Content:",
          type: "text",
          placeholder: "",
          required: true,
          hidden: true,
          initial: "content"
        },
        {
          name: "feedType",
          size: "col-md-5 col",
          label: "feedType:",
          type: "text",
          placeholder: "",
          required: true,
          hidden: true,
          initial: "feedType"
        },
        {
          name: "fixtureId",
          size: "col-md-5 col",
          label: "fixtureId:",
          type: "text",
          placeholder: "",
          required: true,
          hidden: true,
          initial: "fixtureId"
        },
        {
          name: "locale",
          size: "col-md-5 col",
          label: "locale:",
          type: "text",
          placeholder: "",
          required: true,
          hidden: true,
          initial: "locale"
        },
        {
          name: "outputFormat",
          size: "col-md-2 col",
          label: "Output Format:",
          labeltranslation: "outputformat",
          type: "text",
          placeholder: "",
          required: true,
          initial: "outputFormat",
          options: OUTPUT_FORMATS
        },
        {
          name: "outputURL",
          size: "col-md-3 col",
          label: "Output URL:",
          labeltranslation: "outputurl",
          type: "text",
          placeholder: "",
          required: (field, values) => ["RTMP", "SRT"].includes(values.outputFormat),
          condition: "outputFormat",
          conditionValue: (field, values) => ["RTMP", "SRT"].includes(field.value),
          initial: "outputURL",
          validate: "streamURL",
          errors: {
            validate: "Expected valid value"
          }
        },
        {
          name: "outputResolution",
          size: "col-md-5 col",
          label: "outputResolution:",
          type: "text",
          placeholder: "",
          required: true, // TODO: This should be required only for RTMP and SRT
          hidden: true,
          initial: "outputResolution",
          condition: "outputFormat",
          conditionValue: (field, values) => true // TODO: This should be optional for RTMP_PULL and HLS and should be detected on the server side
        },
        {
          name: "muxRate",
          size: "col-md-2 col",
          label: "Output mux rate:",
          labeltranslation: "mux.rate",
          type: "number",
          help: "in bps",
          required: (field, values) => values.outputFormat === "SRT",
          hidden: true,
          initial: "muxRate",
          min: 0,
          computeWatch: ["outputFormat", "outputResolution"],
          compute: computeMuxRate,
          condition: "outputFormat",
          conditionValue: "SRT"
        },
        {
          name: "streamName",
          size: "col-md-3 col",
          label: "Stream Name / Key:",
          labeltranslation: "stream.name",
          type: "text",
          placeholder: "",
          required: (field, values) => values.outputFormat === "RTMP",
          initial: "streamName",
          validate: "streamName",
          errors: {
            validate: "Allowed characters are letters, numbers and the following symbols: . : / & _ ? = ; - @ \\"
          },
          condition: "outputFormat",
          conditionValue: "RTMP"
        },
        {
          name: "subscriptionName",
          size: "col-md-3 col",
          label: "Subscription Name",
          labeltranslation: "subscription.name",
          type: "text",
          placeholder: "",
          required: true,
          initial: "subscriptionName"
        },
        {
          name: "provider",
          size: "col-md-3 col",
          label: "Provider:",
          labeltranslation: "provider",
          type: "select",
          placeholder: "",
          required: true,
          hidden: true,
          initial: "provider",
          options: inputSourceOptionsProvider
        },
        {
          name: "sourceNumber",
          size: "col-md-5 col",
          label: "Source Number:",
          labeltranslation: "sourcenumber",
          type: "number",
          placeholder: "",
          required: true,
          hidden: true,
          initial: "sourceNumber",
          min: 0
        }
      ]
    },
    AddBundle: {
      title: "Add Bundle",
      endpoint: "v1/#0/organizations/#1/fixtures/#2/bundle",
      parameters: ["sport", "organizationId", "fixtureId"],
      method: "POST",
      successmessage: "Bundle added successfully.",
      link: {
        show: false,
        message: "View Bundles",
        path: "/bundles/",
        parameters: []
      },
      canSubmit: (formDefinition, values) => {
        if (typeof values.bundleId === "undefined" || values.bundleId === "") {
          return false;
        }
        return true;
      },
      submit: "Add",
      formClass: "addBundleForm",
      fields: [
        {
          name: "bundleId",
          size: "col-md-12 col",
          label: "Add Bundle:",
          type: "lookup",
          placeholder: "",
          required: false,
          initial: "",
          validate: "number",
          lookup: {
            endpoint: "v1/bundles",
            text: "name",
            value: "bundleId",
            parameters: []
          },
          errors: {
            validate: "Selection is required"
          }
        },
        {
          name: "fixtureId",
          size: "col-sm-2 col",
          label: "fixture Id:",
          type: "text",
          placeholder: "",
          required: true,
          hidden: true,
          initial: "fixtureId"
        },
        {
          name: "organizationId",
          size: "col-sm-2 col",
          label: "organization Id:",
          type: "text",
          placeholder: "",
          required: true,
          hidden: true,
          initial: "organizationId"
        }
      ]
    },
    EditSubscription: {
      title: "Edit Subscription",
      titletranslation: "subscription.edit",
      endpoint: "v1/#0/organizations/#1/fixtures/#2/output/#3",
      parameters: ["sport", "organizationId", "fixtureId", "subscriptionId"],
      method: "PUT",
      successmessage: "Subscription Updated",
      successmessagetranslation: "subscription.updated",
      submit: "Update",
      submittranslation: "update",
      formClass: "subscription-options",
      fields: [
        {
          name: "feedType",
          size: "col-md-6 col",
          label: "Feed Type:",
          labeltranslation: "feedType",
          type: "select",
          placeholder: "",
          required: true,
          initial: "feedType",
          options: feedTypeOptionsProvider
        },
        {
          name: "content",
          size: "col-md-6 col",
          label: "Content:",
          labeltranslation: "content",
          type: "select",
          placeholder: "",
          required: true,
          initial: "content",
          options: programContentOptionsProvider
        },
        {
          name: "sourceNumber",
          size: "col-md-3 col",
          label: "Source Number:",
          labeltranslation: "sourcenumber",
          type: "number",
          placeholder: "",
          required: true,
          initial: "sourceNumber",
          min: 0
        },
        {
          name: "fixtureId",
          size: "col-sm-8 col",
          label: "fixtureId:",
          type: "text",
          placeholder: "",
          required: true,
          hidden: true,
          initial: "fixtureId"
        },
        {
          name: "locale",
          size: "col-sm-8 col",
          label: "Locale:",
          type: "text",
          placeholder: "",
          required: true,
          hidden: true,
          initial: "locale"
        },
        {
          name: "provider",
          size: "col-md-3 col",
          label: "Provider:",
          labeltranslation: "provider",
          type: "select",
          placeholder: "",
          required: true,
          initial: "provider",
          options: inputSourceOptionsProvider
        },
        {
          name: "audio",
          size: "col-md-6 col",
          label: "Audio:",
          labeltranslation: "audio",
          type: "select",
          placeholder: "",
          required: true,
          initial: "audio",
          options: audioSourcesOptionsProvider
        },
        {
          name: "outputFormat",
          size: "col-md-3 col",
          label: "Output Format:",
          labeltranslation: "outputformat",
          type: "select",
          placeholder: "",
          required: true,
          initial: "outputFormat",
          options: OUTPUT_FORMATS
        },
        {
          name: "outputURL",
          size: "col-md-9 col",
          label: "Output URL:",
          labeltranslation: "outputurl",
          type: "text",
          placeholder: "",
          required: (field, values) => ["RTMP", "SRT"].includes(values.outputFormat),
          condition: "outputFormat",
          conditionValue: (field, values) => ["RTMP", "SRT"].includes(field.value),
          initial: "outputURL",
          validate: "streamURL",
          errors: {
            validate: "Expected valid value"
          }
        },
        {
          name: "outputResolution",
          size: "col-md-6 col",
          label: "Output Resolution:",
          labeltranslation: "outputresolution",
          type: "select",
          placeholder: "",
          required: (field, values) => ["RTMP", "SRT"].includes(values.outputFormat),
          initial: "outputResolution",
          options: outputResolutionOptionsProvider,
          condition: "outputFormat",
          conditionValue: (field, values) => ["RTMP", "SRT"].includes(field.value)
        },
        {
          name: "muxRate",
          size: "col-md-3 col",
          label: "Output mux rate:",
          labeltranslation: "mux.rate",
          type: "number",
          help: "in bps",
          required: (field, values) => values.outputFormat === "SRT",
          initial: "muxRate",
          min: 0,
          computeWatch: ["outputFormat", "outputResolution"],
          compute: computeMuxRate,
          condition: "outputFormat",
          conditionValue: "SRT"
        },
        {
          name: "streamName",
          size: "col-md-3 col",
          label: "Stream Name / Key:",
          labeltranslation: "stream.name",
          type: "text",
          placeholder: "",
          required: (field, values) => values.outputFormat === "RTMP",
          initial: "streamName",
          validate: "streamName",
          errors: {
            validate: "Allowed characters are letters, numbers and the following symbols: . : / & _ ? = ; - @ \\"
          },
          condition: "outputFormat",
          conditionValue: "RTMP"
        },
        {
          name: "subscriptionName",
          size: "col-md-3 col",
          label: "Subscription Name",
          labeltranslation: "subscription.name",
          type: "text",
          placeholder: "",
          required: true,
          initial: "subscriptionName"
        }
      ],
      customActions: [
        {
          action: "subscriptions.edit.close",
          label: "Close",
          color: "outline-secondary",
          align: "right",
          rightIcon: "fas fa-times"
        }
      ]
    },
    // Integrations
    AddIntegrations: {
      title: "Add Integrations",
      endpoint: "v1/#0/organizations/#1/fixtures/integrations",
      parameters: ["sport", "organizationId"],
      method: "POST",
      successmessage: "Integration added successfully.",
      canSubmit: (formDefinition, values) => {
        return values.integrations.length > 0;
      },
      submit: "Update",
      fields: [
        {
          name: "integrations",
          size: "col-md-12 col",
          label: "Available integrations",
          type: "checkboxarray",
          placeholder: "",
          className: "form-control checkboxes",
          required: false,
          initial: "integrations",
          options: integrationsOptionsProvider,
          optionsClass: "col col-lg-4"
        }
      ],
      customActions: [
        {
          action: "integrations.add.close",
          label: "Close",
          color: "outline-secondary",
          align: "right",
          rightIcon: "fas fa-times"
        }
      ],
      encoder: (values, formData) => {
        const data = formData.fixtures.map((fixture) => ({
          fixtureId: fixture.fixtureId,
          seasonId: formData.seasonId,
          integrations: values.integrations
        }));
        return data;
      }
    }
  }
};

export default formSetup;
